<template>
	<div>
		<stop-modals :modelName="modelName"></stop-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<stop-form-header :modelPk="modelPk"></stop-form-header>
		</pui-form-header>
		<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
			<v-tab :key="0" :href="'#maintab'">{{ $t('stop.tabs.maintab') }}</v-tab>
			<v-tab :key="1" :href="'#operations'" v-if="!isCreatingElement && hasOperationFunctionality">{{ $t('stop.tabs.operations') }}</v-tab>
			<v-tab :key="2" :href="'#resourceallocation'" v-if="!isCreatingElement && !isModalDialog && hasResAllocationFunctionality">{{
				$t('stop.tabs.resourceallocation')
			}}</v-tab>
			<v-tab :key="3" :href="'#documents'" v-if="!isCreatingElement && showDocuments">{{ $t('pui9.documents.tab') }}</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tabmodel">
			<v-tab-item :key="0" lazy :value="'maintab'">
				<v-row dense class="pui-form-layout">
					<v-col cols="12">
						<pui-field-set :title="$t('stop.visitdata')" v-if="isCreatingElement">
							<v-row dense>
								<v-col cols="2">
									<pui-select
										:id="`portname-${modelName}`"
										:attach="`portname-${modelName}`"
										:label="this.$t('stop.portname')"
										toplabel
										clearable
										required
										:disabled="formDisabled || !enablePort"
										v-model="model"
										modelName="port"
										:itemsToSelect="[{ id: this.model.portid }]"
										:modelFormMapping="{ id: 'portid' }"
										itemValue="id"
										itemText="portname"
										reactive
										:fixedFilter="filterByUserPortsAndAuthority"
									></pui-select>
								</v-col>
								<v-col cols="8">
									<pui-select
										:id="`visitid-${modelName}`"
										:attach="`visitid-${modelName}`"
										:label="$t('stop.portcallnumber')"
										toplabel
										clearable
										required
										:disabled="!model.portid || !enableVisit"
										v-model="model"
										modelName="visit"
										:itemsToSelect="[{ id: this.model.visitid }]"
										:modelFormMapping="{ id: 'visitid' }"
										:itemValue="['id']"
										:itemText="
											(item) =>
												`${item.portcallnumber} - ${item.vesselname} - ${this.$dateTimeUtils.getLocalFormattedDate(
													item.eta,
													'DD/MM/YYYY HH:mm'
												)} - ${this.$dateTimeUtils.getLocalFormattedDate(item.etd, 'DD/MM/YYYY HH:mm')} - ${item.status}`
										"
										:order="{ eta: 'asc' }"
										:fixedFilter="portidFixedFilter"
										reactive
									></pui-select>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
				<v-row dense class="pui-form-layout">
					<v-col cols="8">
						<pui-field-set :title="$t('stop.data')">
							<v-row dense>
								<v-col cols="2" v-if="!isCreatingElement">
									<pui-number-field
										:id="`num-${modelName}`"
										:label="$t('stop.stopnum')"
										v-model="model.num"
										maxlength="4"
										decimals="0"
										min="0"
										max="999999999"
										toplabel
										:disabled="true"
									></pui-number-field>
								</v-col>
								<v-col cols="3" v-if="!isCreatingElement">
									<pui-select
										:id="`statusid-${modelName}`"
										:attach="`statusid-${modelName}`"
										:label="$t('stop.stopStatus')"
										toplabel
										clearable
										required
										disabled
										v-model="model"
										modelName="stopstatuspa"
										:itemsToSelect="itemsToSelectStatus"
										:modelFormMapping="{ stopstatuscode: 'statusid' }"
										itemValue="stopstatuscode"
										itemText="stopstatusname"
										reactive
									></pui-select>
								</v-col>
								<v-col cols="1" class="align-self-center">
									<pui-checkbox
										:id="`manual-${modelName}`"
										:label="$t('stop.manual')"
										v-model="model.manual"
										:disabled="formDisabled || disableForm"
									></pui-checkbox>
								</v-col>
								<v-col cols="2" class="align-self-center">
									<pui-checkbox
										:id="`exemptpilotage-${modelName}`"
										:label="$t('stop.exemptpilotage')"
										v-model="model.exemptpilotage"
										:disabled="formDisabled || disableForm"
									></pui-checkbox>
								</v-col>
								<v-col cols="3" class="align-self-center">
									<pui-checkbox
										:id="`extendedstay-${modelName}`"
										:label="$t('stop.extendedstay')"
										v-model="model.extendedstay"
										:disabled="formDisabled || disableForm"
									></pui-checkbox>
								</v-col>
								<v-col cols="1" class="align-self-center">
									<pui-checkbox
										:id="`fault-${modelName}`"
										:label="$t('stop.fault')"
										v-model="model.fault"
										:disabled="formDisabled || disableForm"
									></pui-checkbox>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="7">
									<pui-text-area
										v-if="model.commentsstatus"
										:id="`commentsstatus-stop`"
										v-model="model.commentsstatus"
										:label="$t('stop.commentsstatus')"
										:disabled="true"
										toplabel
										maxlength="350"
									></pui-text-area>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
					<v-col cols="4">
						<pui-field-set :title="$t('stop.supplies')">
							<v-row dense>
								<v-col cols="2" class="my-7 align-self-center">
									<pui-checkbox
										:id="`water-${modelName}`"
										:label="$t('stop.water')"
										v-model="model.water"
										:disabled="formDisabled || disableForm"
									></pui-checkbox>
								</v-col>
								<v-col cols="2" class="align-self-center">
									<pui-checkbox
										:id="`ice-${modelName}`"
										:label="$t('stop.ice')"
										v-model="model.ice"
										:disabled="formDisabled || disableForm"
									></pui-checkbox>
								</v-col>
								<v-col cols="3" class="align-self-center">
									<pui-checkbox
										:id="`fuel-${modelName}`"
										:label="$t('stop.fuel')"
										v-model="model.fuel"
										:disabled="formDisabled || disableForm"
									></pui-checkbox>
								</v-col>
								<v-col cols="4" class="align-self-center">
									<pui-checkbox
										:id="`electricity-${modelName}`"
										:label="$t('stop.electricity')"
										v-model="model.electricity"
										:disabled="formDisabled || disableForm"
									></pui-checkbox>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
				<v-row dense class="pui-form-layout">
					<v-col cols="12">
						<pui-field-set :title="$t('stop.requesteddata')">
							<v-row dense>
								<v-col cols="3">
									<pui-date-field
										:id="`startdatesoli-${modelName}`"
										:label="$t('stop.arrivaldate')"
										v-model="model.startdatesoli"
										toplabel
										required
										:disabled="formDisabled || disableForm || !model.visitid"
										time
										:min="visit.eta ? visit.eta : null"
										:max="visit.etd ? visit.etd : null"
										:key="keyVisit"
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-date-field
										:id="`enddatesoli-${modelName}`"
										:label="$t('stop.departuredate')"
										v-model="model.enddatesoli"
										toplabel
										required
										:disabled="formDisabled || disableForm || !model.visitid"
										time
										:min="model.startdatesoli"
										:max="visit.etd ? visit.etd : null"
										:key="keyVisit"
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-date-field
										:id="`pbaestimated-${modelName}`"
										:label="$t('stop.pbaestimated')"
										v-model="model.pbaestimated"
										toplabel
										:disabled="true"
										time
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`berthingtypesoli-${modelName}`"
										:attach="`berthingtypesoli-${modelName}`"
										:label="$t('stop.berthingtype')"
										toplabel
										clearable
										required
										v-model="model"
										modelName="berthingtype"
										:itemsToSelect="itemsToSelectBerthingtypereq"
										:modelFormMapping="{ berthingtypecode: 'berthingtype' }"
										itemValue="berthingtypecode"
										itemText="berthingtypename"
										:order="{ berthingtypename: 'asc' }"
										reactive
										:disabled="formDisabled || disableForm"
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="3">
									<pui-select
										:id="`berthsoliid-${modelName}`"
										:attach="`berthsoliid-${modelName}`"
										:label="$t('stop.berthsoliid')"
										toplabel
										clearable
										required
										:disabled="formDisabled || disableForm"
										v-model="model"
										modelName="berth"
										:itemsToSelect="itemsToSelectBerthreq"
										:modelFormMapping="{ id: 'berthid' }"
										itemValue="id"
										:itemText="(item) => (item.berthsubcode ? `${item.name} - ${item.berthsubcode}` : item.name)"
										:order="{ name: 'asc' }"
										reactive
									></pui-select>
								</v-col>
								<v-col cols="1">
									<pui-select
										:id="`bollardinisoliid-${modelName}`"
										:attach="`bollardinisoliid-${modelName}`"
										:label="$t('stop.bollardini')"
										toplabel
										:disabled="formDisabled || disableForm"
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: this.model.bollardinisoliid }]"
										:modelFormMapping="{ id: 'bollardiniid' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardIniReqFixedFilter"
									></pui-select>
								</v-col>
								<v-col cols="1">
									<pui-select
										:id="`bollardendsoliid-${modelName}`"
										:attach="`bollardendsoliid-${modelName}`"
										:label="$t('stop.bollardend')"
										toplabel
										:disabled="formDisabled || disableForm"
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: this.model.bollardendsoliid }]"
										:modelFormMapping="{ id: 'bollardendid' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardEndReqFixedFilter"
									></pui-select>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`activitytypesoli-${modelName}`"
										:attach="`activitytypesoli-${modelName}`"
										:label="$t('stop.activitytype')"
										toplabel
										clearable
										required
										:disabled="formDisabled || disableForm"
										v-model="model"
										modelName="activitytype"
										:itemsToSelect="itemsToSelectActivitytypereq"
										:modelFormMapping="{ code: 'activitytype' }"
										itemValue="code"
										itemText="name"
										:order="{ name: 'asc' }"
										reactive
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-number-field
										:id="`draftentrance-${modelName}`"
										:label="$t('stop.draftentrance')"
										v-model="model.draftentrance"
										maxlength="5"
										decimals="2"
										min="0"
										max="999.99"
										toplabel
										:disabled="formDisabled || disableForm"
									></pui-number-field>
								</v-col>
								<v-col cols="2">
									<pui-number-field
										:id="`draftoutlet-${modelName}`"
										:label="$t('stop.draftoutlet')"
										v-model="model.draftoutlet"
										maxlength="5"
										decimals="2"
										min="0"
										max="999.99"
										toplabel
										:disabled="formDisabled || disableForm"
									></pui-number-field>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
				<v-row dense class="pui-form-layout" v-if="!isCreatingElement && hasPlStatus">
					<v-col cols="12">
						<pui-field-set :title="$t('stop.planneddata')">
							<v-row dense>
								<v-col cols="3">
									<pui-date-field
										:id="`startdateplan-${modelName}`"
										:label="$t('stop.arrivaldate')"
										v-model="model.startdateplan"
										toplabel
										:disabled="true"
										time
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-date-field
										:id="`enddateplan-${modelName}`"
										:label="$t('stop.departuredate')"
										v-model="model.enddateplan"
										toplabel
										:disabled="true"
										time
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`berthingtypeplan-${modelName}`"
										:attach="`berthingtypeplan-${modelName}`"
										:label="$t('stop.berthingtype')"
										toplabel
										clearable
										v-model="model"
										modelName="berthingtype"
										:itemsToSelect="itemsToSelectBerthingtypeplan"
										:modelFormMapping="{ berthingtypecode: 'berthingtypeplan' }"
										itemValue="berthingtypecode"
										itemText="berthingtypename"
										:order="{ berthingtypename: 'asc' }"
										reactive
										:disabled="true"
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="4">
									<pui-select
										:id="`berthplanid-${modelName}`"
										:attach="`berthplanid-${modelName}`"
										:label="$t('stop.berthplan')"
										toplabel
										clearable
										:disabled="true"
										v-model="model"
										modelName="berth"
										:itemsToSelect="itemsToSelectBerthplan"
										:modelFormMapping="{ id: 'berthplanid' }"
										itemValue="id"
										:itemText="(item) => (item.berthsubcode ? `${item.name} - ${item.berthsubcode}` : item.name)"
										:order="{ name: 'asc' }"
										reactive
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardiniplanid-${modelName}`"
										:attach="`bollardiniplanid-${modelName}`"
										:label="$t('stop.bollardini')"
										toplabel
										:disabled="true"
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: this.model.bollardiniplanid }]"
										:modelFormMapping="{ id: 'bollardiniplanid' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardIniPlanFixedFilter"
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardendplanid-${modelName}`"
										:attach="`bollardendplanid-${modelName}`"
										:label="$t('stop.bollardend')"
										toplabel
										:disabled="true"
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: this.model.bollardendplanid }]"
										:modelFormMapping="{ id: 'bollardendplanid' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardEndPlanFixedFilter"
									></pui-select>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
				<v-row dense class="pui-form-layout" v-if="!isCreatingElement">
					<v-col cols="12">
						<pui-field-set :title="$t('stop.authorizeddata')">
							<v-row dense>
								<v-col cols="3">
									<pui-date-field
										:id="`startdateauth-${modelName}`"
										:label="$t('stop.arrivaldate')"
										v-model="model.startdateauth"
										toplabel
										:disabled="true"
										time
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-date-field
										:id="`enddateauth-${modelName}`"
										:label="$t('stop.departuredate')"
										v-model="model.enddateauth"
										toplabel
										:disabled="true"
										time
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-date-field
										:id="`pbaauthorized-${modelName}`"
										:label="$t('stop.pbaauthorized')"
										v-model="model.pbaauthorized"
										toplabel
										:disabled="true"
										time
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`berthingtypeauth-${modelName}`"
										:attach="`berthingtypeauth-${modelName}`"
										:label="$t('stop.berthingtype')"
										toplabel
										clearable
										v-model="model"
										modelName="berthingtype"
										:itemsToSelect="itemsToSelectBerthingtypeauth"
										:modelFormMapping="{ berthingtypecode: 'berthingtypeauth' }"
										itemValue="berthingtypecode"
										itemText="berthingtypename"
										:order="{ berthingtypename: 'asc' }"
										reactive
										:disabled="true"
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="4">
									<pui-select
										:id="`berthauthid-${modelName}`"
										:attach="`berthauthid-${modelName}`"
										:label="$t('stop.berthactual')"
										toplabel
										clearable
										:disabled="true"
										v-model="model"
										modelName="berth"
										:itemsToSelect="itemsToSelectBerthauth"
										:modelFormMapping="{ id: 'berthauthid' }"
										itemValue="id"
										:itemText="(item) => (item.berthsubcode ? `${item.name} - ${item.berthsubcode}` : item.name)"
										:order="{ name: 'asc' }"
										reactive
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardiniauthid-${modelName}`"
										:attach="`bollardiniauthid-${modelName}`"
										:label="$t('stop.bollardini')"
										toplabel
										:disabled="true"
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: this.model.bollardiniauthid }]"
										:modelFormMapping="{ id: 'bollardiniauthid' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardIniAuthFixedFilter"
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardendauthid-${modelName}`"
										:attach="`bollardendauthid-${modelName}`"
										:label="$t('stop.bollardend')"
										toplabel
										:disabled="true"
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: this.model.bollardendauthid }]"
										:modelFormMapping="{ id: 'bollardendauthid' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardEndAuthFixedFilter"
									></pui-select>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
				<v-row dense class="pui-form-layout" v-if="!isCreatingElement">
					<v-col cols="12">
						<pui-field-set :title="$t('stop.actualdata')">
							<v-row dense>
								<v-col cols="3">
									<pui-date-field
										:id="`startdatereal-${modelName}`"
										:label="$t('stop.arrivaldate')"
										v-model="model.startdatereal"
										toplabel
										:disabled="true"
										time
									></pui-date-field>
									<pui-checkbox
										:id="`isautoata-${modelName}`"
										:label="$t('stop.isautoata')"
										v-model="model.isautoata"
										disabled
									></pui-checkbox>
								</v-col>
								<v-col cols="3">
									<pui-date-field
										:id="`enddatereal-${modelName}`"
										:label="$t('stop.departuredate')"
										v-model="model.enddatereal"
										toplabel
										:disabled="true"
										time
									></pui-date-field>
									<pui-checkbox
										:id="`isautoatd-${modelName}`"
										:label="$t('stop.isautoatd')"
										v-model="model.isautoatd"
										disabled
									></pui-checkbox>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`berthingtypereal-${modelName}`"
										:attach="`berthingtypereal-${modelName}`"
										:label="$t('stop.berthingtype')"
										toplabel
										clearable
										v-model="model"
										modelName="berthingtype"
										:itemsToSelect="itemsToSelectBerthingtypereal"
										:modelFormMapping="{ berthingtypecode: 'berthingtypereal' }"
										itemValue="berthingtypecode"
										itemText="berthingtypename"
										:order="{ berthingtypename: 'asc' }"
										reactive
										:disabled="true"
									></pui-select>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`activitytypereal-${modelName}`"
										:attach="`activitytypereal-${modelName}`"
										:label="$t('stop.activitytype')"
										toplabel
										clearable
										:disabled="true"
										v-model="model"
										modelName="activitytype"
										:itemsToSelect="itemsToSelectActivitytypeactual"
										:modelFormMapping="{ code: 'activitytypereal' }"
										itemValue="code"
										itemText="name"
										:order="{ name: 'asc' }"
										reactive
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="4">
									<pui-select
										:id="`berthrealid-${modelName}`"
										:attach="`berthrealid-${modelName}`"
										:label="$t('stop.berthactual')"
										toplabel
										clearable
										:disabled="true"
										v-model="model"
										modelName="berth"
										:itemsToSelect="itemsToSelectBerthactual"
										:modelFormMapping="{ id: 'berthrealid' }"
										itemValue="id"
										:itemText="(item) => (item.berthsubcode ? `${item.name} - ${item.berthsubcode}` : item.name)"
										:order="{ name: 'asc' }"
										reactive
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardinirealid-${modelName}`"
										:attach="`bollardinirealid-${modelName}`"
										:label="$t('stop.bollardini')"
										toplabel
										:disabled="true"
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: this.model.bollardinirealid }]"
										:modelFormMapping="{ id: 'bollardinirealid' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardIniActualFixedFilter"
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardendrealid-${modelName}`"
										:attach="`bollardendrealid-${modelName}`"
										:label="$t('stop.bollardend')"
										toplabel
										:disabled="true"
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: this.model.bollardendrealid }]"
										:modelFormMapping="{ id: 'bollardendrealid' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardEndActualFixedFilter"
									></pui-select>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>

				<v-row dense class="pui-form-layout">
					<custom-fields-generate-form
						:formDisabled="formDisabled || disableForm"
						:isCreatingElement="isCreatingElement"
						:parentObject="model"
						:parentModelName="modelName"
						:parentPk="pk"
					></custom-fields-generate-form>
					<v-col cols="12">
						<pui-text-area
							:id="`comments-stop`"
							v-model="model.comments"
							:label="$t('stop.comments')"
							:disabled="formDisabled || disableForm"
							toplabel
							maxlength="300"
						></pui-text-area>
					</v-col>
				</v-row>
			</v-tab-item>
			<v-tab-item :key="1" :value="`operations`" lazy v-if="!isCreatingElement && hasOperationFunctionality">
				<v-flex xs12>
					<pui-master-detail
						componentName="operationgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ id: 'stop_id' }"
						:formDisabled="formDisabled"
						:modalDialog="isModalDialog"
						:parentModel="model"
					></pui-master-detail>
				</v-flex>
			</v-tab-item>
			<v-tab-item :key="2" lazy :value="'resourceallocation'" v-if="!isCreatingElement && !isModalDialog && hasResAllocationFunctionality">
				<v-flex xs12>
					<pui-master-detail
						componentName="resourceallocationgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ id: 'stop_id' }"
						:formDisabled="formDisabled"
						:modalDialog="isModalDialog"
						:parentModel="model"
					></pui-master-detail>
				</v-flex>
			</v-tab-item>
			<v-tab-item :key="3" :value="`documents`" v-if="!isCreatingElement && showDocuments">
				<pui-master-detail componentName="PuiDocumentGrid" :parentModelName="selectedModelName" :parentPk="pk"></pui-master-detail>
			</v-tab-item>
		</v-tabs-items>
		<!-- Page Footer -->
		<pui-form-footer v-if="!isModalDialog">
			<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
		</pui-form-footer>
		<!-- </v-form> -->
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import stopActions from './StopActions';
import stopModals from './StopModals.vue';
import StopFormHeader from './StopFormHeader.vue';

export default {
	name: 'stop-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'stop-modals': stopModals,
		StopFormHeader
	},
	data() {
		return {
			modelName: 'stop',
			actions: stopActions.formactions,
			modelBerthingType: {},
			selectedModelName: '',
			disableForm: true,
			portidFixedFilter: null,
			enableVisit: false,
			enablePort: false,
			hasPlStatus: false,
			visit: {},
			keyVisit: 0
		};
	},
	watch: {
		'model.berthingtypereq'(newValue) {
			this.getBerthingType();
		},
		'model.portid'(newVal) {
			if (Object.keys(this.$attrs).length === 0) {
				if (newVal != null) {
					this.portidFixedFilter = {
						groups: [],
						groupOp: 'and',
						rules: [
							{ field: 'portid', op: 'eq', data: newVal },
							{ field: 'statusid', op: 'in', data: this.visitStatusCodes }
						]
					};
					this.enableVisit = true;
				} else {
					this.enablePort = true;
				}
			}
		},
		'model.visitid'(newVal) {
			if (this.isCreatingElement) {
				if (newVal === null && this.$attrs.visitpk) {
					this.model.visitid = JSON.parse(atob(this.$attrs.visitpk)).id;
				}
				this.getVisitEtaEtd();
			}
		}
	},
	afterGetData() {},
	mounted() {
		if (!this.isCreatingElement) {
			this.disableForm = true;
			this.getDocumentModelName();
			const stopStatus = this.$store.getters.stopsStatusValues;
			this.hasPlStatus = stopStatus.some((status) => status.stopstatuscode === 'PL');
		} else {
			this.disableForm = false;
			this.model.portauthorityid = window.localStorage.getItem('workingPortAuthorityId');
			if (this.$attrs.visitpk) {
				this.model.visitid = JSON.parse(atob(this.$attrs.visitpk)).id;
				this.enableVisit = false;
				if (!this.model.portid) {
					this.getPortIdFromVisit();
					if (this.model.portid) {
						this.enablePort = false;
					}
				}
			}
		}

		this.getBerthingType();
	},
	methods: {
		afterGetData() {
			if (this.model.statusid == 'IN') {
				this.model.enddatereal = null;
			}
		},
		getBerthingType() {
			const opts = {
				model: 'berthingtype',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'berthingtypecode',
							op: 'eq',
							data: this.model.berthingtypereq
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					this.modelBerthingType = response.data.data[0];
				}
			});
		},
		getDocumentModelName() {
			const currentPath = this.$router.currentRoute.path.split('/');
			this.selectedModelName = currentPath[1];
		},
		getVisitEtaEtd() {
			if (this.model.visitid) {
				const opts = {
					model: 'visit',
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [{ field: 'id', op: 'eq', data: this.model.visitid }]
					}
				};
				this.$puiRequests.postRequest('/puisearch', opts, (response) => {
					if (response.data.data[0]) {
						this.visit.eta = response.data.data[0].eta;
						this.visit.etd = response.data.data[0].etd;
						this.keyVisit++;
					}
				});
			}
		},
		getPortIdFromVisit() {
			const opts = {
				model: 'visit',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [{ field: 'id', op: 'eq', data: this.model.visitid }]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data.length) {
					this.model.portid = response.data.data[0].portid;
				}
			});
		}
	},

	computed: {
		itemsToSelectStatus() {
			return [{ stopstatuscode: this.model.statusid }];
		},
		itemsToSelectBerthingtypereq() {
			return [{ berthingtypecode: this.model.berthingtypesoli }];
		},
		itemsToSelectBerthingtypeauth() {
			return [{ berthingtypecode: this.model.berthingtypeauth }];
		},
		itemsToSelectBerthingtypeplan() {
			return [{ berthingtypecode: this.model.berthingtypeplan }];
		},
		itemsToSelectBerthingtypereal() {
			return [{ berthingtypecode: this.model.berthingtypereal }];
		},
		itemsToSelectBerthreq() {
			return [{ id: this.model.berthsoliid }];
		},
		itemsToSelectBerthplan() {
			return [{ id: this.model.berthplanid }];
		},
		itemsToSelectBerthauth() {
			return [{ id: this.model.berthauthid }];
		},
		itemsToSelectBerthactual() {
			return [{ id: this.model.berthrealid }];
		},
		itemsToSelectActivitytypereq() {
			return [{ code: this.model.activitytypesoli }];
		},
		itemsToSelectActivitytypeactual() {
			return [{ code: this.model.activitytypereal }];
		},
		bollardIniReqFixedFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'berthid', op: 'eq', data: this.model.berthid }]
			};
		},
		bollardEndReqFixedFilter() {
			let berth = this.$store.getters.getRawPortBerths.find((berth) => berth.id == this.model.berthid);
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					berth && berth.dockid
						? { field: 'dockid', op: 'eq', data: berth.dockid }
						: { field: 'berthid', op: 'eq', data: this.model.berthid }
				]
			};
		},
		bollardIniPlanFixedFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'berthid', op: 'eq', data: this.model.berthplanid }]
			};
		},
		bollardEndPlanFixedFilter() {
			let berth = this.$store.getters.getRawPortBerths.find((berth) => berth.id == this.model.berthplanid);
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					berth && berth.dockid
						? { field: 'dockid', op: 'eq', data: berth.dockid }
						: { field: 'berthid', op: 'eq', data: this.model.berthplanid }
				]
			};
		},
		bollardIniAuthFixedFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'berthid', op: 'eq', data: this.model.berthauthid }]
			};
		},
		bollardEndAuthFixedFilter() {
			let berth = this.$store.getters.getRawPortBerths.find((berth) => berth.id == this.model.berthauthid);
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					berth && berth.dockid
						? { field: 'dockid', op: 'eq', data: berth.dockid }
						: { field: 'berthid', op: 'eq', data: this.model.berthauthid }
				]
			};
		},
		bollardIniActualFixedFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'berthid', op: 'eq', data: this.model.berthrealid }]
			};
		},
		bollardEndActualFixedFilter() {
			let berth = this.$store.getters.getRawPortBerths.find((berth) => berth.id == this.model.berthrealid);
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					berth && berth.dockid
						? { field: 'dockid', op: 'eq', data: berth.dockid }
						: { field: 'berthid', op: 'eq', data: this.model.berthrealid }
				]
			};
		},
		hasOperationFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_OPERATION');
		},
		hasResAllocationFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_RESOURCEALLOCATION');
		},
		filterByUserPortsAndAuthority() {
			const userPorts = this.$store.getters.getPortsIds;
			const userPortAuthority = window.localStorage.getItem('workingPortAuthorityId');
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'id', op: 'in', data: userPorts },
					{ field: 'portauthorityid', op: 'eq', data: userPortAuthority }
				]
			};
		},
		visitStatusCodes() {
			return this.$store.getters.getActiveVisitStatusid;
		}
	},
	created() {}
};
</script>
